<template>
<div class="tw-flex tw-w-full tw-flex-col lg:">
  <icons name="arrow-back" />
  <div class="company-dashboard lg:tw-mt-10 tw-mt-5 tw-p-5">
    <div class="tw-flex tw-w-full tw-items-center tw-flex-col">
      <div class="tw-flex tw-w-full tw-items-center tw-justify-between">
      <h6 class="header">POS Management</h6>
      <div class="mr-2 tw-mt-5 lg:tw-mt-0 " style="border-radius: 10px; border: 1px solid rgba(79, 79, 79, 0.08)">
        <v-btn text class="request-pos" @click="handleModal"> Add POS Request</v-btn>
      </div>
      </div>
      <div class="tw-flex  tw-w-full tw-items-center ">
        <v-text-field
            placeholder="Search for company"
            class="p-0"
            solo
            flat
            hide-details
            v-model="search"
        >
          <template #prepend>
            <v-icon :color="color" class="ml-5">
              mdi-magnify
            </v-icon>
          </template>
          <template #append>
            <v-icon :color="color">
              mdi-filter-variant
            </v-icon>
          </template>
        </v-text-field>
      </div>
      <v-data-table
          :headers="headers"
          :mobile-breakpoint="0"
          :search="search"
          item-key="id"
          :single-select="false"
          height="available"
          :hide-default-footer="posRequest.length <= 7"
          :items="posRequest"
          :no-data-text="not_found"
          class="mt-4 mx-1 table"
          :loading="loading"


      >
        <template #header.companyLogo>
          <span class="status">Company Logo</span>
        </template>
        <template v-slot:[`item.companyLogo`]="{ item }">
          <img
              :src="item.companyLogo"
              alt="logo"
              v-if="item.companyLogo"
              class="company-logo"
          />
          <div
              class="no-image"
              v-else
              :style="{ backgroundColor: color }"

          >
            <h6 class="tw-flex tw-justify-center tw-items-center ">
              {{ item.transportCompanyName[0] }}
            </h6>
          </div>
        </template>
        <template #header.companyName>
          <span class="status">Company Name</span>
        </template>
        <template v-slot:[`item.companyName`]="{ item }">
          <span class="td-style">{{ item.transportCompanyName }}</span>
        </template>

        <template #header.terminal>
          <span class="status">Terminal Name</span>
        </template>
        <template v-slot:[`item.terminal`]="{ item }">
          <span class="td-style">{{ item.terminalName }}</span>
        </template>

        <template #header.totalNumberOfPos>
          <span class="status">Total Numbers of POS Requested</span>
        </template>
        <template v-slot:[`item.totalNumberOfPos`]="{ item }">
          <span class="td-style">{{ getTotalRequested(item) }}</span>
        </template>
        <template #header.totalNumberOfPosApproved>
          <span class="status">Total Numbers of POS Approved</span>
        </template>
        <template v-slot:[`item.totalNumberOfPosApproved`]="{ item }">
          <span class="td-style">{{ item.totalApproved }}</span>
        </template>
        <template #header.totalAmountPaid>
          <span class="status">Total Amount Paid For Pos</span>
        </template>
        <template v-slot:[`item.totalAmountPaid`]="{ item }">
          <span class="td-style">{{ item.totalAmountPaidForPos }}</span>
        </template>
        <template #header.totalBalance>
          <span class="status">Total Balance Owing For Pos</span>
        </template>
        <template v-slot:[`item.totalBalance`]="{ item }">
          <span class="td-style">{{ item.totalAmountOwingForPos }}</span>
        </template>
        <template #header.date>
          <span class="status">Date Of Request</span>
        </template>
        <template v-slot:[`item.date`]="{ item }">
          <span class="td-style">{{ formatDate(item.createdAt) }}</span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-menu bottom offset-y left>
            <template v-slot:activator="{ on, attrs }">
              <td class="d-flex justify-center">
                <v-btn v-bind="attrs" v-on="on" icon>
                  <v-icon :color="color">mdi-dots-horizontal</v-icon>
                </v-btn>
              </td>
            </template>
            <v-list>
              <v-list-item class=""  @click="routeToApprovePosRequest(item.id)">
                <v-list-item-title
                    style="color: black"
                    class="tw-flex-row tw-flex tw-cursor-pointer menu-text">
                  <img
                      alt=""
                      class="tw-mr-2 icon-size"
                  />
                  Approve
                </v-list-item-title>
              </v-list-item>
              <v-list-item class="" >
                <v-list-item-title
                    style="color: black"
                    class="tw-flex-row tw-flex tw-cursor-pointer menu-text">
                  <img
                      alt=""
                      class="tw-mr-2 icon-size"
                  />
                  View

                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </div>
  </div>
  <modal :dialog="showModal" title="Add POS Request" icon="pos"
         @close="handleModal"
        :loading="modalLoading" section="pos"/>
</div>
</template>

<script>
import Icons from "../reuseables/Icons.vue";
import {getAllPosRequest} from "@/services/api/APIService";
import Modal from "../reuseables/Modal.vue";
import dayjs from "dayjs";
export default {
  name: "PosDashboardView",
  components: {Modal, Icons},
  data(){
    return{
      color: "#004AAD",
      search : "",
      posRequest:[],
      not_found : "Transport Companies will show here",
      headers: [
        { text: "", value: "companyLogo" },
        { text: "", value: "companyName" },
        { text: "", value: "terminal" },
        { text: "", value: "totalNumberOfPos" },
        {text: "", value: "totalNumberOfPosApproved"},
        { text: "", value: "totalAmountPaid" },
        { text: "", value: "totalBalance" },
        { text: "", value: "date" },
        { text: "", value: "actions", align: "end" },
      ],
      loading : false,
      showModal : false,
      modalLoading : false,
      modalTitle: "",
      modalDesc : " ",
      transportCompanyId:null,

    }
  },

  methods:{
    handleModal(){
      if (this.showModal){
        this.getAllRequest()
      }
      this.showModal = !this.showModal
    },
    async getAllRequest(){
      this.loading = true
      await getAllPosRequest().then(res =>{
        res.data.forEach(request =>{
          let totalApproved = 0
          request.requestedPos.forEach(pos => {
            if (pos.approvalStatus === "APPROVED"){
              totalApproved++
            }
          })
          request.totalApproved = totalApproved
        })
        this.posRequest = res.data
        console.log(this.posRequest)
        this.loading = false
      }).catch(() => {
        this.$displaySnackbar({
          message: "error occurred",
          success: false
        })
        this.loading = false
      })
    },
    getTotalRequested(item){
      return item.requestedPos.length
    },
    routeToApprovePosRequest(requestId){
      this.$router.push({name: "ApprovePos", params: {requestId : requestId}})
    },
    formatDate(date){
      return dayjs(date).format('lll')
    },

  },
 async created() {
    await this.getAllRequest()
  }
}
</script>

<style scoped lang="scss">
.company-dashboard{
  display: flex;
  background: #FFFFFF;
  width: 100%;
  min-height: 70vh;
  border-radius: 10px;
  flex-direction: column;
}
.header{
  font-family: 'Inter', sans-serif ;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #004AAD;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.table{
  width: 100%;
}

.v-data-table__wrapper > table {
  margin-top: 55px !important;
}

.theme--light.v-data-table
> .v-data-table__wrapper
> table
> thead
> tr
> th:first-child,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td:first-child {
  padding-left: 16px;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.status {
  font-family: "Poppins",sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;
  color: #4f4f4f;
  padding: 0 0 0 5px;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.td-style {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 15px;
  letter-spacing: 0;
  text-align: left;
  text-transform: capitalize;

}
.company-logo{
  height: 32px;
  width: 32px;
  border-radius: 50%;
}

.no-image{
  display: flex;
    height: 32px;
    width: 32px;
    border-radius: 50%;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  text-align: center;
}

.request-pos{
  display: flex;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  align-items: center;
  justify-content: center;
  color: #FFFFFF !important;
  background-color: #004aad;
  text-align: center;
  text-transform: capitalize !important;
}
::v-deep .v-data-table--fixed-height .v-data-table__wrapper::-webkit-scrollbar {
  background: transparent !important;
  width: auto;
  height: 5px;
}
::v-deep .v-data-table--fixed-height .v-data-table__wrapper::-webkit-scrollbar-thumb {
  background: #c4c4c4 !important;
  max-height: 1px !important;
  border-radius: 10px;
}
</style>